import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import ProtocolPage from './pages/ProtocolPage';
import BlotterPage from './pages/BlotterPage';
import './App.css';

function SearchBar() {
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    // For now, we'll just suggest 'Protocol Example' if it matches the search
    if (value.toLowerCase().includes('protocol example')) {
      setSuggestions(['Protocol Example']);
    } else {
      setSuggestions([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (search.toLowerCase() === 'protocol example') {
      navigate('/protocol/example');
    }
    // Clear search and suggestions after submitting
    setSearch('');
    setSuggestions([]);
  };

  return (
    <form onSubmit={handleSubmit} className="search-form">
      <input
        type="text"
        value={search}
        onChange={handleSearch}
        placeholder="Search protocols..."
        className="search-input"
      />
      {suggestions.length > 0 && (
        <ul className="search-suggestions">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => {
              navigate('/protocol/example');
              setSearch('');
              setSuggestions([]);
            }}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </form>
  );
}

function App() {
  return (
    <Router>
      <div className="container">
        <header className="retro-header">
          <Link to="/" className="logo-link">
            <img src="/images/logo.svg" alt="DefiDeputy" className="logo-svg" />
          </Link>
        </header>
        <nav className="retro-nav">
          <div className="nav-content">
            <Link to="/blotter" className="nav-link">Blotter</Link>
            <SearchBar />
          </div>
        </nav>
        <main className="main-content">
          <Routes>
            <Route path="/protocol/:id" element={<ProtocolPage />} />
            <Route path="/blotter" element={<BlotterPage />} />
            <Route path="/" element={<BlotterPage />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;